<template>
  <div>
  <router-view/>
    
    
  </div>
</template>

<script>

export default {
  name: 'App',

  
}
</script>

<style>


body, html {
  margin: 0;
  padding: 0;
}
</style>
