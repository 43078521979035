
import { createRouter , createWebHistory } from "vue-router";

const routes = [
{
    path: '/',
    component: () => import('../views/home/index.vue')
},
{
    path:'/login',
    component: () => import('../views/home/login.vue')

}
]

const router = createRouter({
    history: createWebHistory(),//路由模式createWebHashHistory
    routes
})

export default router;